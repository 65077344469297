@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;1,500&display=swap);
html,
body,
#root {
  height: 100%;
  font-size: 1rem;
  letter-spacing: 0.03rem;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  line-height: 1.3;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
textarea,
select,
button {
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 0.04rem;
  line-height: 1.5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  cursor: pointer;
}

h2 {
  font-weight: 300;
  font-size: 60px;
  letter-spacing: -0.0083rem;
}

h3 {
  font-weight: 400;
  font-size: 48px;
  letter-spacing: 0;
}

h4 {
  font-weight: 400;
  font-size: 34px;
  letter-spacing: 0.0073rem;
}

h5 {
  font-weight: 400;
  font-size: 24px;
  letter-spacing: 0;
}

h6 {
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0.0075rem;
}

